var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-4"},[_c('div',[_c('label',{attrs:{"for":""}},[_vm._v("\n      "+_vm._s(_vm.$t('PROFILE_SETTINGS.FORM.SYSTEM_ALERTS.EXTRA_SETTINGS.SOUND.TITLE'))+"\n      "),_c('select',{staticClass:"tone-selector",domProps:{"value":_vm.notificationTone},on:{"input":_vm.onSelect}},_vm._l((_vm.notificationAlertTones),function(tone){return _c('option',{key:tone.value,domProps:{"value":tone.value}},[_vm._v("\n          "+_vm._s(tone.label)+"\n        ")])}),0)])]),_vm._v(" "),_c('div',{staticClass:"flex"},[_c('input',{staticClass:"notification--checkbox",attrs:{"id":"audio_alert_when_tab_is_inactive","type":"checkbox"},domProps:{"value":_vm.alwaysPlayAudioAlert,"checked":_vm.alwaysPlayAudioAlert},on:{"input":function($event){return _vm.$emit('input', {
          alwaysPlayAudioAlert: !_vm.alwaysPlayAudioAlert,
        })}}}),_vm._v(" "),_c('label',{attrs:{"for":"audio_alert_when_tab_is_inactive"}},[_vm._v("\n      "+_vm._s(_vm.$t(
          'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.CONDITIONS.CONDITION_ONE'
        ))+"\n    ")])]),_vm._v(" "),_c('div',{staticClass:"flex"},[_c('input',{staticClass:"notification--checkbox",attrs:{"id":"audio_alert_until_all_conversations_are_read","type":"checkbox"},domProps:{"value":_vm.alertIfUnreadConversationExist,"checked":_vm.alertIfUnreadConversationExist},on:{"input":function($event){return _vm.$emit('input', {
          alertIfUnreadConversationExist: !_vm.alertIfUnreadConversationExist,
        })}}}),_vm._v(" "),_c('label',{attrs:{"for":"audio_alert_until_all_conversations_are_read"}},[_vm._v("\n      "+_vm._s(_vm.$t(
          'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.CONDITIONS.CONDITION_TWO'
        ))+"\n    ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }