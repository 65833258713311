/* eslint arrow-body-style: 0 */
import { frontendURL } from '../../../helper/URLHelper';
import DStudioView from "./DStudioView.vue";

export const routes = [
  {
    path: frontendURL('accounts/:accountId/dstudio'),
    name: 'dstudio_home',
    component: DStudioView,
    roles: ['administrator', 'agent'],
    props: route => {
      return { accountId: route.params.accountId };
    },
  },
]
