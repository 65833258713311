/* global axios */

import ApiClient from '../ApiClient';

class DStudioAPI extends ApiClient {
  constructor() {
    super('integrations/dstudio', { accountScoped: true });
  }

  getLoginLink() {
    return axios.get(`${this.url}/url`);
  }
}

export default new DStudioAPI();
