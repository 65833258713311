<template>
  <div id="profile-settings-notifications">
    <div class="profile--settings--row text-black-900 dark:text-slate-300 row">
      <div class="columns small-3">
        <h4 class="block-title text-black-900 dark:text-slate-200">
          {{ $t('PROFILE_SETTINGS.FORM.SYSTEM_ALERTS.TITLE') }}
        </h4>
      </div>
      <div class="columns small-7">
        <notification-table-wrap
          v-if="!getUIFlags.isFetching"
          :value="notificationSettings"
          :ui-settings="uiSettings"
          @ui-input="updateUISettings"
          @input="updateNotificationSettings"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import hasPermissionMixin from 'dashboard/mixins/hasPermission';
import NotificationTableWrap from './NotificationTable.vue';

export default {
  components: { NotificationTableWrap },
  mixins: [alertMixin, configMixin, uiSettingsMixin, hasPermissionMixin],
  computed: {
    ...mapGetters({
      notificationSettings: 'userNotificationSettings/getNotificationSettings',
      getUIFlags: 'userNotificationSettings/getUIFlags',
      uiSettings: 'getUISettings',
      features: 'getCurrentFeatures', // d99d
      getAccount: 'accounts/getAccount',
      accountId: 'getCurrentAccountId',
    }),
  },
  mounted() {
    this.$store.dispatch('userNotificationSettings/get');
  },
  methods: {
    async updateNotificationSettings(event) {
      try {
        this.$store.dispatch('userNotificationSettings/update', event);
        this.showAlert(this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_SUCCESS'));
      } catch (error) {
        this.showAlert(this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_ERROR'));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';

.notification--checkbox {
  font-size: $font-size-large;
}

.push-notification--button {
  margin-bottom: var(--space-one);
}

.notification-items--wrapper {
  margin-bottom: var(--space-smaller);
}

.notification-label {
  display: flex;
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--space-small);
}

.tone-selector {
  height: var(--space-large);
  padding-bottom: var(--space-micro);
  padding-top: var(--space-micro);
  width: var(--space-mega);
}
</style>
