import Vue from 'vue';
import * as types from '../mutation-types';
import UserNotificationSettings from '../../api/userNotificationSettings';

const state = {
  record: {},
  uiFlags: {
    isFetching: false,
    isUpdating: false,
  },
};

export const getters = {
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getNotificationSettings: $state => {
    const { sound, email, bell, push, mobile, extra } = $state.record;
    if ([sound, email, bell, push, mobile].filter(x => x).length === 0)
      return {};
    return {
      sound,
      email,
      bell,
      push,
      mobile,
      extra,
    };
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.default.SET_USER_NOTIFICATION_UI_FLAG, { isFetching: true });
    try {
      const response = await UserNotificationSettings.get();
      commit(types.default.SET_USER_NOTIFICATION, response.data);
      commit(types.default.SET_USER_NOTIFICATION_UI_FLAG, {
        isFetching: false,
      });
    } catch (error) {
      commit(types.default.SET_USER_NOTIFICATION_UI_FLAG, {
        isFetching: false,
      });
    }
  },

  update: async ({ commit }, params) => {
    commit(types.default.SET_USER_NOTIFICATION_UI_FLAG, { isUpdating: true });
    try {
      const response = await UserNotificationSettings.update({
        notification_settings: params,
      });
      commit(types.default.SET_USER_NOTIFICATION, response.data);
      commit(types.default.SET_USER_NOTIFICATION_UI_FLAG, {
        isUpdating: false,
      });
    } catch (error) {
      commit(types.default.SET_USER_NOTIFICATION_UI_FLAG, {
        isUpdating: false,
      });
      throw error;
    }
  },
};

export const mutations = {
  [types.default.SET_USER_NOTIFICATION_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_USER_NOTIFICATION]: ($state, data) => {
    Vue.set($state, 'record', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
