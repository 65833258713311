<script>
import {
  hasPushPermissions,
  requestPushPermissions,
  verifyServiceWorkerExistence,
} from '../../../../helper/pushHelper';

export default {
  data() {
    return {
      hasEnabledPushPermissions: false,
    };
  },
  mounted() {
    if (hasPushPermissions()) {
      this.getPushSubscription();
    }
  },
  methods: {
    onRegistrationSuccess() {
      this.hasEnabledPushPermissions = true;
    },
    onRequestPermissions() {
      requestPushPermissions({
        onSuccess: this.onRegistrationSuccess,
      });
    },
    getPushSubscription() {
      verifyServiceWorkerExistence(registration =>
        registration.pushManager
          .getSubscription()
          .then(subscription => {
            if (!subscription) {
              this.hasEnabledPushPermissions = false;
            } else {
              this.hasEnabledPushPermissions = true;
            }
          })
          // eslint-disable-next-line no-console
          .catch(error => console.log(error))
      );
    },
    onTestPush() {
      verifyServiceWorkerExistence(registration =>
        registration.showNotification('Test notification', {
          data: {
            url: window.location.href,
          },
        })
      );
    },
  },
};
</script>
<template>
  <div class="p-4 flex justify-center">
    <div class="text-center">
      <div class="push-notification--button p-1">
        <woot-submit-button
          :disabled="hasEnabledPushPermissions"
          :button-text="
            $t(
              'PROFILE_SETTINGS.FORM.SYSTEM_ALERTS.EXTRA_SETTINGS.CHROME.ENABLE'
            )
          "
          class="button nice small"
          type="button"
          @click="onRequestPermissions"
        />
      </div>
      <div class="push-notification--button p-1">
        <woot-submit-button
          :disabled="!hasEnabledPushPermissions"
          :button-text="
            $t('PROFILE_SETTINGS.FORM.SYSTEM_ALERTS.EXTRA_SETTINGS.CHROME.TEST')
          "
          class="button nice small"
          type="button"
          @click="onTestPush"
        />
      </div>
    </div>
  </div>
</template>
