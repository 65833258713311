<template>
    <div v-if="isLoading" class="dd-loader" style="position: relative;">
      <spinner />
    </div>
    <div v-else-if="remoteUrl" style="width: 100%">
        <iframe
                type="text/html"
                :src="remoteUrl"
                style="width: inherit; height: 100%;">
        </iframe>
    </div>
    <div v-else-if="account" class="builder-empty-state" style="width: 100%">
        <div class="builder-empty" >
            <div>
                <img src="~dashboard/assets/images/lock.svg" alt="No Workflow" />
            </div>
        </div>
    </div>
</template>

<script>
import dstudioApi from 'dashboard/api/integrations/dstudio';
import {mapGetters} from "vuex";
import Spinner from 'shared/components/Spinner.vue';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {Spinner},
  mixins: [alertMixin],
  props: {
    accountId: [String, Number]
  },
  data() {
    return {
      remoteUrl: null,
      isLoading: true
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount'
    }),
    account() {
      return this.getAccount(this.accountId)
    },
  },
  methods: {
    async load() {
      this.isLoading = true;
      try {
        const { data } = await dstudioApi.getLoginLink();
        this.remoteUrl = data?.url;
      } catch (error) {
        this.showAlert(this.$t('INTEGRATION_SETTINGS.API_ERROR_MESSAGE'));
      } finally {
        this.isLoading = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.inbox-empty-state {
  height: 100%;
  overflow: auto;
}

.builder-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
      margin: var(--space-normal);
      width: 10rem;
    }

    span {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-medium);
      text-align: center;
    }
  }
}

.builder-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
