export const GENERAL_PERMISSIONS = {
  Contacts: { create: false, read: false, update: false, delete: false },
  Reports: { create: 'no', read: false, update: 'no', delete: 'no' },
  Campaigns: { create: false, read: false, update: false, delete: false },
  'WhatsApp Templates': {
    create: false,
    read: false,
    update: 'no',
    delete: false,
  },
  'Studio': {
    create: 'no',
    read: false,
    update: 'no',
    delete: 'no',
  },
  'Workflow Builder': {
    create: 'no',
    read: false,
    update: 'no',
    delete: 'no',
  },
  ChatGPT: { create: false, read: false, update: false, delete: false },
  'Mobile App': {
    create: 'no',
    read: false,
    update: 'no',
    delete: 'no',
  },
  Webhooks: { create: false, read: false, update: false, delete: false },
  'External Dashboard': {
    create: false,
    read: false,
    update: false,
    delete: false,
  },
  'Direct Chat': {
    create: 'no',
    read: false,
    update: 'no',
    delete: 'no',
  },
  'Account settings': {
    create: 'no',
    read: false,
    update: false,
    delete: 'no',
  },
  Agents: { create: false, read: false, update: false, delete: false },
  Roles: { create: false, read: false, update: false, delete: false },
  Teams: { create: false, read: false, update: false, delete: false },
  Inbox: { create: false, read: false, update: false, delete: false },
  Labels: { create: false, read: false, update: false, delete: false },
  'Custom attributes': {
    create: false,
    read: false,
    update: false,
    delete: false,
  },
  Automations: {
    create: false,
    read: false,
    update: false,
    delete: false,
  },
  Macros: { create: false, read: false, update: false, delete: false },
  'Canned Responses': {
    create: false,
    read: false,
    update: false,
    delete: false,
  },
  'Audit Logs': {
    create: 'no',
    read: false,
    update: 'no',
    delete: 'no',
  },
  Sla: {
    create: false,
    read: false,
    update: false,
    delete: false,
  },
  'Export Wizard': {
    create: false,
    read: 'no',
    update: 'no',
    delete: 'no',
  },
  'Help and Support': {
    create: 'no',
    read: false,
    update: 'no',
    delete: 'no',
  },
  Changelog: { create: 'no', read: false, update: 'no', delete: 'no' },
  'Api Docs': {
    create: 'no',
    read: false,
    update: 'no',
    delete: 'no',
  },
  'Profile page': {
    create: 'no',
    read: false,
    update: false,
    delete: 'no',
  },
};

export const INBOX_PERMISSIONS = [
  'Inbox read (Collaborator alias)',
  'Inbox read all conversations',
  'Inbox new message',
  'Inbox reply',
  'Inbox private notes',
  'Inbox attach files',
  'Inbox delay message',
  'Inbox can assign',
  'Inbox can be assigned',
  'Inbox resolve',
  'Inbox reopen',
  'Inbox manual/bot switch',
  'Inbox delete message',
  'Inbox ai assistance',
  'Inbox priority of conversations',
  'Inbox assign labels',
  'Inbox assign to group',
  'Inbox send Transcript',
  'Inbox canned responses',
  'Inbox export conversations',
];
