<script>
const CONVERSATION_CREATION = 'conversation_creation';
const CONVERSATION_ASSIGNMENT = 'conversation_assignment';
const ASSIGNED_CONVERSATION_NEW_MESSAGE = 'assigned_conversation_new_message';
const CONVERSATION_MENTION = 'conversation_mention';
const SLA_BREACH = 'sla_breach';
const MESSAGE_STATUS = 'message_status';
const MESSAGE_SENT_STATUS = 'message_sent_status';

const NOTIFICATION_TYPES = [
  CONVERSATION_CREATION,
  ASSIGNED_CONVERSATION_NEW_MESSAGE,
  CONVERSATION_ASSIGNMENT,
  CONVERSATION_MENTION,
  MESSAGE_STATUS,
  MESSAGE_SENT_STATUS,
  SLA_BREACH,
];
import { VeTable } from 'vue-easytable';
import PushExtraCell from './PushExtraCell.vue';
import EmailExtraCell from './EmailExtraCell.vue';
import SoundExtraCell from './SoundExtraCell.vue';

export default {
  components: { VeTable, PushExtraCell, EmailExtraCell, SoundExtraCell },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    uiSettings: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    columns() {
      return [
        {
          title: '',
          field: 'label',
          key: 'label',
        },
        {
          title: this.$t('PROFILE_SETTINGS.FORM.SYSTEM_ALERTS.AUDIO'),
          field: 'sound',
          key: 'sound',
          renderBodyCell: ({ row, column }) => {
            const data = { [row.key]: !row[column.key] };
            return (
              <div>
                <input
                  class="notification--checkbox"
                  type="checkbox"
                  checked={row[column.key]}
                  onClick={() => this.onClick(column.key, data)}
                />
              </div>
            );
          },
        },
        {
          title: this.$t('PROFILE_SETTINGS.FORM.SYSTEM_ALERTS.EMAIL'),
          field: 'email',
          key: 'email',
          renderBodyCell: ({ row, column }) => {
            const data = { [row.key]: !row[column.key] };
            return (
              <div>
                <input
                  class="notification--checkbox"
                  type="checkbox"
                  checked={row[column.key]}
                  onClick={() => this.onClick(column.key, data)}
                />
              </div>
            );
          },
        },
        {
          title: this.$t('PROFILE_SETTINGS.FORM.SYSTEM_ALERTS.CHROME'),
          field: 'push',
          key: 'push',
          renderBodyCell: ({ row, column }) => {
            const data = { [row.key]: !row[column.key] };
            return (
              <div>
                <input
                  class="notification--checkbox"
                  type="checkbox"
                  checked={row[column.key]}
                  onClick={() => this.onClick(column.key, data)}
                />
              </div>
            );
          },
        },
        {
          title: this.$t('PROFILE_SETTINGS.FORM.SYSTEM_ALERTS.NOTIFICATION'),
          field: 'bell',
          key: 'bell',
          renderBodyCell: this.renderCheckBoxBodyCell,
        },
        {
          title: this.$t('PROFILE_SETTINGS.FORM.SYSTEM_ALERTS.MOBILE'),
          field: 'mobile',
          key: 'mobile',
          renderBodyCell: this.renderCheckBoxBodyCell,
        },
      ];
    },
    notifications() {
      const entries = Object.entries(this.value);
      if (!entries.length) return [];
      const group = entries.map(([key, value]) => {
        return Object.entries(value).reduce((acc, [setting, val]) => {
          return {
            ...acc,
            [setting]: {
              [key]: val,
            },
          };
        }, {});
      });
      return [
        ...NOTIFICATION_TYPES.map(key => {
          return group.reduce((acc, setting) => {
            return {
              ...acc,
              ...setting[key],
              label: this.$t(
                `PROFILE_SETTINGS.FORM.SYSTEM_ALERTS.EVENTS.${key.toLocaleUpperCase()}`
              ),
              key: key,
            };
          }, {});
        }),
      ];
    },
  },
  methods: {
    handleSoundExtraInput(args) {
      const [key, value] = Object.entries(args)[0];
      const newKey =
        {
          alertIfUnreadConversationExist:
            'alert_if_unread_assigned_conversation_exist',
          alwaysPlayAudioAlert: 'always_play_audio_alert',
          notificationTone: 'notification_tone',
        }[key] || key;
      this.$emit('ui-input', {
        [newKey]: value,
      });
    },
    onClick(field, data) {
      this.$emit('input', {
        [field]: {
          ...this.value[field],
          ...data,
        },
      });
    },
    renderCheckBoxBodyCell({ row, column }) {
      const data = { [row.key]: !row[column.key] };
      return (
        <div>
          <input
            class="notification--checkbox"
            type="checkbox"
            checked={row[column.key]}
            onClick={() => this.onClick(column.key, data)}
          />
        </div>
      );
    },
  },
};
</script>
<template>
  <div>
    <ve-table
      max-height="calc(100vh - 350px)"
      :columns="columns"
      :table-data="notifications"
      :border-around="false"
      :fixed-header="true"
    />
    <div class="row">
      <div class="small-12 medium-6 large-6 column">
        <SoundExtraCell
          :always-play-audio-alert="uiSettings.always_play_audio_alert"
          :alert-if-unread-conversation-exist="
            uiSettings.alert_if_unread_assigned_conversation_exist
          "
          :notification-tone="uiSettings.notification_tone"
          @input="handleSoundExtraInput"
        />
      </div>
      <div class="small-12 medium-3 large-3 column">
        <EmailExtraCell
          v-if="value.extra"
          :value="value.extra.email"
          @input="onClick('extra', { email: $event })"
        />
      </div>
      <div class="small-12 medium-3 large-3 column">
        <PushExtraCell />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  .ve-table .ve-table-container {
    overflow-y: hidden;
  }
}
</style>
