import { FEATURE_FLAGS } from '../../../../featureFlags';
import { frontendURL } from '../../../../helper/URLHelper';

const primaryMenuItems = accountId => [
  {
    icon: 'chat',
    key: 'conversations',
    label: 'CONVERSATIONS',
    toState: frontendURL(`accounts/${accountId}/dashboard`),
    toStateName: 'home',
    roles: ['administrator', 'agent'],
  },
  {
    icon: 'book-contacts',
    key: 'contacts',
    label: 'CONTACTS',
    featureFlag: FEATURE_FLAGS.CRM,
    toState: frontendURL(`accounts/${accountId}/contacts`),
    toStateName: 'contacts_dashboard',
    roles: ['administrator', 'agent'],
  },
  {
    icon: 'arrow-trending-lines',
    key: 'reports',
    label: 'REPORTS',
    featureFlag: FEATURE_FLAGS.REPORTS,
    toState: frontendURL(`accounts/${accountId}/reports`),
    toStateName: 'settings_account_reports',
    roles: ['administrator'],
  },
  {
    icon: 'megaphone',
    key: 'campaigns',
    label: 'CAMPAIGNS',
    featureFlag: FEATURE_FLAGS.CAMPAIGNS,
    toState: frontendURL(`accounts/${accountId}/campaigns`),
    toStateName: 'settings_account_campaigns',
    roles: ['administrator'],
  },
  {
    icon: 'whatsapp',
    key: 'waTemplates',
    label: 'WA_TEMPLATES',
    hasSubMenu: false,
    toState: frontendURL(`accounts/${accountId}/wasettings/templates/list`),
    toStateName: 'online_wa_templates_list',
    roles: ['administrator'],
  },
  // {
  //   icon: 'library',
  //   key: 'helpcenter',
  //   label: 'HELP_CENTER.TITLE',
  //   featureFlag: FEATURE_FLAGS.HELP_CENTER,
  //   alwaysVisibleOnChatwootInstances: true,
  //   toState: frontendURL(`accounts/${accountId}/portals`),
  //   toStateName: 'default_portal_articles',
  //   roles: ['administrator'],
  // },
  {
    icon: 'bot',
    key: 'studio',
    label: 'STUDIO',
    hasSubMenu: false,
    toState: frontendURL(`accounts/${accountId}/dstudio`),
    toStateName: 'dstudio_home',
    roles: ['administrator', 'agent'],
  },
  {
    icon: 'ion-wand',
    key: 'builder',
    label: 'BUILDER',
    hasSubMenu: false,
    toState: frontendURL(`accounts/${accountId}/builder`),
    toStateName: 'builder_home',
    roles: ['administrator', 'agent'],
  },
  {
    icon: 'flash-on',
    key: 'integrations',
    label: 'INTEGRATIONS',
    hasSubMenu: false,
    toState: frontendURL(`accounts/${accountId}/settings-integrations`),
    toStateName: 'settings_integrations',
    roles: ['administrator'],
    featureFlag: FEATURE_FLAGS.INTEGRATIONS,
  },
  {
    icon: 'settings',
    key: 'settings',
    label: 'SETTINGS',
    toState: frontendURL(`accounts/${accountId}/settings`),
    toStateName: 'settings_home',
    roles: ['administrator', 'agent'],
  },
  {
    icon: 'ion-help',
    key: 'help',
    label: 'HElP_AND_SUPPORT',
    hasSubMenu: false,
    toState: frontendURL(`accounts/${accountId}/help`),
    toStateName: 'help_home',
    roles: ['administrator', 'agent'],
  },
  {
    icon: 'ion-information-circled',
    key: 'changelog',
    label: 'CHANGELOG',
    hasSubMenu: false,
    toState: frontendURL(`accounts/${accountId}/changelog`),
    toStateName: 'changelog_index',
    roles: ['administrator', 'agent'],
  },
  {
    icon: 'ion-document',
    key: 'waDocs',
    label: 'WA_DOCS',
    hasSubMenu: false,
    toState: frontendURL(`accounts/${accountId}/wasettings/docs`),
    toStateName: 'online_wa_docs',
    roles: ['administrator'],
  },
];

export default primaryMenuItems;
