<script>
import { mapGetters } from 'vuex';
import { getAudioContext } from 'shared/helpers/AudioNotificationHelper';

import hasPermissionMixin from 'dashboard/mixins/hasPermission';
export default {
  mixins: [hasPermissionMixin],
  props: {
    alwaysPlayAudioAlert: {
      type: Boolean,
      default: false,
    },
    alertIfUnreadConversationExist: {
      type: Boolean,
      default: false,
    },
    notificationTone: {
      type: String,
      default: 'ding',
    },
  },

  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      accountId: 'getCurrentAccountId',
    }),
    notificationAlertTones() {
      return this.$t('PROFILE_SETTINGS.FORM.SYSTEM_ALERTS.TONES');
    },
    account() {
      return this.getAccount(this.accountId);
    },
    newRulesSystemEnabled() {
      return this.account?.features?.new_rules_system;
    },
  },
  methods: {
    onSelect({ target: { value } }) {
      this.$emit('input', { notification_tone: value });
      this.playSound(value);
    },
    async playSound(sound) {
      const audioCtx = getAudioContext();
      if (audioCtx) {
        const resourceUrl = `/audio/dashboard/${sound}.mp3`;
        const audioRequest = new Request(resourceUrl);

        fetch(audioRequest)
          .then(response => response.arrayBuffer())
          .then(buffer => {
            audioCtx.decodeAudioData(buffer).then(audioBuffer => {
              const source = audioCtx.createBufferSource();
              source.buffer = audioBuffer;
              source.connect(audioCtx.destination);
              source.loop = false;
              source.start();
            });
            // eslint-disable-next-line no-promise-executor-return
            return new Promise(res => res());
          });
      }
    },
  },
};
</script>
<template>
  <div class="p-4">
    <div>
      <label for="">
        {{
          $t('PROFILE_SETTINGS.FORM.SYSTEM_ALERTS.EXTRA_SETTINGS.SOUND.TITLE')
        }}
        <select
          :value="notificationTone"
          class="tone-selector"
          @input="onSelect"
        >
          <option
            v-for="tone in notificationAlertTones"
            :key="tone.value"
            :value="tone.value"
          >
            {{ tone.label }}
          </option>
        </select>
      </label>
    </div>
    <div class="flex">
      <input
        id="audio_alert_when_tab_is_inactive"
        :value="alwaysPlayAudioAlert"
        :checked="alwaysPlayAudioAlert"
        class="notification--checkbox"
        type="checkbox"
        @input="
          $emit('input', {
            alwaysPlayAudioAlert: !alwaysPlayAudioAlert,
          })
        "
      />
      <label for="audio_alert_when_tab_is_inactive">
        {{
          $t(
            'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.CONDITIONS.CONDITION_ONE'
          )
        }}
      </label>
    </div>
    <div class="flex">
      <input
        id="audio_alert_until_all_conversations_are_read"
        :value="alertIfUnreadConversationExist"
        :checked="alertIfUnreadConversationExist"
        class="notification--checkbox"
        type="checkbox"
        @input="
          $emit('input', {
            alertIfUnreadConversationExist: !alertIfUnreadConversationExist,
          })
        "
      />
      <label for="audio_alert_until_all_conversations_are_read">
        {{
          $t(
            'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.CONDITIONS.CONDITION_TWO'
          )
        }}
      </label>
    </div>
  </div>
</template>
