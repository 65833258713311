import { render, staticRenderFns } from "./DStudioView.vue?vue&type=template&id=8b80ccfc&scoped=true&"
import script from "./DStudioView.vue?vue&type=script&lang=js&"
export * from "./DStudioView.vue?vue&type=script&lang=js&"
import style0 from "./DStudioView.vue?vue&type=style&index=0&id=8b80ccfc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b80ccfc",
  null
  
)

export default component.exports