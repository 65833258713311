<script>
export default {
  props: {
    value: {
      type: String,
      default: () => '',
    },
  },
};
</script>
<template>
  <div class="p-4">
    <label for="">
      {{ $t('PROFILE_SETTINGS.FORM.SYSTEM_ALERTS.EXTRA_SETTINGS.EMAIL.LABEL') }}
      <input
        type="text"
        :value="value"
        :placeholder="
          $t(
            'PROFILE_SETTINGS.FORM.SYSTEM_ALERTS.EXTRA_SETTINGS.EMAIL.PLACEHOLDER'
          )
        "
        @blur="$emit('input', $event.target.value)"
      />
    </label>
  </div>
</template>
